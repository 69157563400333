import React from "react";
import companyLogo from "../../assets/images/company1.png";

import contactLogo from "../../assets/images/contact.png";
import LinkCard from "./LinkCard";

const RecruitLink = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-10">
      <LinkCard
        cardtitle="COMPANY"
        des="会社概要"
        button={"MORE"}
        backgroundImage={companyLogo}
        linkTo="/Company"
      />

      <LinkCard
        cardtitle="CONTACT"
        des="お問い合わせ/パートナー募集"
        button={"MORE"}
        backgroundImage={contactLogo}
        linkTo="/form"
      />
    </div>
  );
};

export default RecruitLink;
