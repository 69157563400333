import React from "react";
import { motion } from "framer-motion";
import otamesi from "../../assets/images/otamesi.png";
import otamesi2 from "../../assets/images/otamesi2.png";

const Section = ({ title, subtitle, content }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      className="text-white my-10 sm:my-20 md:my-40 font-titleFont flex flex-col justify-center items-start gap-4 sm:gap-6 md:gap-12 max-w-5xl mx-auto px-3 sm:px-4 md:px-6 relative z-10"
    >
      <motion.h2
        variants={itemVariants}
        className="text-2xl sm:text-3xl md:text-6xl font-bold text-white uppercase tracking-wider font-minchoFont"
      >
        {title}
      </motion.h2>
      <motion.h1
        variants={itemVariants}
        className="text-3xl sm:text-5xl md:text-8xl font-minchoFont leading-tight"
      >
        {subtitle}
      </motion.h1>
      <motion.div
        variants={itemVariants}
        className="text-base sm:text-lg md:text-2xl leading-relaxed backdrop-blur-sm"
      >
        {content}
      </motion.div>
    </motion.div>
  );
};

const CenterBannerTest = () => {
  return (
    <div className="relative w-full bg-[#141414]">
      <div className="absolute top-0 left-0 right-0 h-10 sm:h-20 md:h-40 bg-gradient-to-b from-[#141414] to-transparent z-10"></div>

      <div className="w-full min-h-screen flex flex-col  bg-gradient-to-br bg-[#141414] font-titleFont py-5 sm:py-10 md:py-20 overflow-hidden relative">
        <img
          src={otamesi}
          alt="otamesi character"
          className="absolute top-1/4 md:top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 opacity-30 w-full sm:w-3/4 md:w-auto"
        />
        <img
          src={otamesi2}
          alt="otamesi2 character"
          className="absolute top-3/4 md:top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 opacity-30 w-full sm:w-3/4 md:w-auto"
        />

        <div className="text-2xl sm:text-4xl md:text-7xl font-minchoFont text-white px-3 sm:px-4 md:px-24 pt-6 sm:pt-12 md:pt-24 relative z-10">
          <h1>ABOUT</h1>
        </div>
        <div className="mb-10 sm:mb-20 md:mb-40">
          <Section
            title="Mission-使命"
            subtitle="全ての人を幸福に"
            content={
              <ul className="list-inside space-y-2 list-none">
                <li>
                  アワーズシップは事業を通じて、すべての人の幸福度向上を使命に活動をします。
                </li>
                <li>
                  技術力を活かして社会に変革をもたらすような仕掛けを積極的に行っていきたいと思っています。
                </li>
              </ul>
            }
          />
        </div>
        <div className="mb-10 sm:mb-20 md:mb-40">
          <Section
            title="Vision-展望"
            subtitle="ITで社会に革新を"
            content={
              <ul className="list-inside space-y-2 list-none">
                <li>弊社はただ技術力が高いだけのIT企業を目指すのではなく、</li>
                <li>
                  技術力を活かして社会に変革をもたらすような仕掛けを積極的に行っていきたいと思っています。
                </li>
                <li>
                  生み出した利益を積極的に新たな事業へ投資を行いワクワクするようなチャレンジをしていきます。
                </li>
              </ul>
            }
          />
        </div>
        <div>
          <Section
            title="Value-価値観"
            content={
              <ul className="list-inside space-y-2 list-none text-lg sm:text-xl md:text-3xl flex flex-col gap-2">
                <li>会社の成長を常に考え行動する。</li>
                <li>仲間と助け合い、高みを目指す。</li>
                <li>人に寄り添い、感謝され信頼を得る。</li>
                <li>働くことが楽しいと思える会社づくりをする。</li>
                <li>ギブアンドギブの精神で価値を提供する。</li>
              </ul>
            }
          />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-10 sm:h-20 md:h-32 bg-gradient-to-t  z-10"></div>
    </div>
  );
};

export default CenterBannerTest;
//from-[#141414] via-gray-900 to-white
