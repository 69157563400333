import React from "react";
import { logo } from "../../assets/index";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex flex-col gap-12 justify-center items-center pt-6 bg-[#141414]">
      <div className="w-full flex flex-col md:flex-row justify-between items-center px-10">
        <HashLink smooth to="/#top">
          <img
            src={logo}
            alt="logo"
            className="w-[150px] md:w-[200px] h-[auto] cursor-pointer"
          ></img>
        </HashLink>
        <ul className="flex flex-col md:flex-row gap-6 md:gap-12 font-titleFont text-lg md:text-xl font-semibold items-center">
          <li className="text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0">
            <HashLink smooth to="/#features">
              事業内容
            </HashLink>
          </li>
          <li className="text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0">
            <Link style={{ textDecoration: "none" }} to="/Company">
              会社概要
            </Link>
          </li>
          <li className="text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0">
            <Link style={{ textDecoration: "none" }} to="/Recruit">
              採用情報
            </Link>
          </li>
          <li className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0">
            <a
              href="https://gaming.oursship.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our’ｓShipGaming
            </a>
          </li>
          <li className="text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0">
            <Link style={{ textDecoration: "none" }} to="/form">
              お問い合わせ/パートナー募集
            </Link>
          </li>
        </ul>
      </div>

      <div className="footer-copyright w-full px-10">
        <hr className="border-gray-600" />
        <p className="text-center text-gray-400 text-sm md:text-base">
          Copyright © 2024 Our's Ship All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
