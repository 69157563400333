import React from "react";
import { Link } from "react-router-dom";

const Card = ({ cardtitle, des, button, backgroundImage, linkTo }) => {
  return (
    <div className="w-full max-w-4xl mx-auto lg:scale-80 lg:transform lg:origin-top-left">
      <div className="relative w-full h-64 xs:h-72 sm:h-80 rounded-lg overflow-hidden">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="absolute inset-0 bg-black opacity-50" />
        <div className="relative z-10 w-full h-full px-4 xs:px-6 sm:px-8 md:px-10 py-6 xs:py-8 sm:py-10 flex items-center transition-colors duration-100">
          <div className="flex flex-col gap-2 xs:gap-3 sm:gap-4">
            <h2 className="text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl pb-2 xs:pb-3 sm:pb-4 md:pb-6 font-titleFont text-white font-bold justify-center items-center">
              {cardtitle}
            </h2>
            <div className="text-sm xs:text-base sm:text-lg md:text-xl pb-2 xs:pb-3 sm:pb-4 font-bodyFont text-white">
              <p>{des}</p>
            </div>
            <div>
              <Link to={linkTo}>
                <button className="bg-white text-black py-1 xs:py-1.5 sm:py-2 px-2 xs:px-3 sm:px-4 rounded-lg text-sm xs:text-base sm:text-lg font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                  {button}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
