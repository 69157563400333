import React from "react";
import RecruitImage from "../../assets/images/recruitship.png";

const RecruitIMG = () => {
  return (
    <div>
      <div
        className="relative w-full h-screen bg-cover bg-center bg-fixed"
        style={{ backgroundImage: `url(${RecruitImage})` }}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-titleFont w-full px-4 sm:px-6 md:px-0">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-white">
            Our's Ship Recruit
          </h1>
          <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white my-3 sm:my-4 md:my-5">
            日本一を目指す
            <br className="sm:hidden" />
            仲間を募集しています
          </h1>
        </div>
      </div>
    </div>
  );
};

export default RecruitIMG;
