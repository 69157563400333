import React from "react";
import RecruitIMG from "./RecruitIMG";
import RecruitMSG from "./RecruitMSG";
import Welfare from "./Welfare";
import ItRecruit from "./ItRecruit";
import Resume from "./Resume";
import RecruitLink from "./RecruitLink";
import { useLocation } from "react-router";
import { useEffect } from "react";

const Recruit = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <RecruitIMG />
      <div className="">
        <RecruitMSG />
      </div>
      <div className="max-w-screen-2xl mx-auto">
        <ItRecruit />
      </div>
      <div className="">
        <Welfare />
      </div>
      <div className="max-w-screen-2xl mx-auto">
        <Resume />
      </div>
      <div className="my-24 max-w-screen-2xl mx-auto">
        <RecruitLink />
      </div>
    </div>
  );
};

export default Recruit;
