import React from "react";
import { MdHolidayVillage, MdOutlineEventNote } from "react-icons/md";
import { FaNotesMedical, FaHandshake } from "react-icons/fa";
import { FaBed, FaTrainSubway } from "react-icons/fa6";

const WelfareCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <Icon className="text-4xl text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Welfare = () => {
  const benefits = [
    {
      icon: MdHolidayVillage,
      title: "年間休日125日以上",
      description: "プライベートも大事にしてください！",
    },
    {
      icon: FaBed,
      title: "年末年始、夏季休暇あり",
      description: "しっかり休んでください！",
    },
    {
      icon: FaNotesMedical,
      title: "社会保険完備",
      description: "雇用・労災・健康・厚生年金",
    },
    {
      icon: FaTrainSubway,
      title: "交通費全額支給",
      description: "社内規定あり",
    },
    {
      icon: MdOutlineEventNote,
      title: "資格取得手当",
      description: "資格受験費用を支給します！",
    },
    {
      icon: FaNotesMedical,
      title: "定期健康診断",
      description: "年に一度受けて頂きます。",
    },
    {
      icon: FaHandshake,
      title: "リファラル採用手当",
      description: "知人の紹介で手当を支給します！",
    },
    {
      icon: MdHolidayVillage,
      title: "有給休暇",
      description: "休みを取りやすい社風です！",
    },
    {
      icon: MdOutlineEventNote,
      title: "書籍購入手当",
      description: "ビジネス書等の購入手当を支給します！",
    },
    {
      icon: MdHolidayVillage,
      title: "長期休暇取得制度",
      description: "希望に応じて1～3ヶ月程度までの休暇を取得可能です！",
    },
  ];

  return (
    <section className="w-full py-20 bg-gradient-to-b from-gray-100 to-white px-6 md:px-16 my-8 md:my-24">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-5xl md:text-7xl font-bold text-black mb-4">
            福利厚生
          </h1>
          <p className="text-xl md:text-2xl text-gray-600">Welfare</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <WelfareCard key={index} {...benefit} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Welfare;
