import React, { useEffect } from "react";
import OursShip from "../Business/OursShip";
import Map from "../Banner/Map";

const Business = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="max-w-screen-2xl mx-auto ">
        <OursShip />
      </div>
      <div className="max-w-screen-2xl mx-auto my-24 pt-10">
        <hr className="my-24 border-t-1 border-gray-300" />
        <Map />
      </div>
    </>
  );
};

export default Business;
