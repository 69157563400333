import React from "react";
import Title from "../Layouts/Title";

const InfoRow = ({ label, value }) => (
  <div className="flex flex-col lg:flex-row border-b border-gray-200 py-6 transition-colors duration-300 hover:bg-gray-50">
    <div className="w-full lg:w-1/3 text-gray-600 font-medium text-xl mb-2 lg:mb-0">
      {label}
    </div>
    <div className="w-full lg:w-2/3 text-black text-xl">{value}</div>
  </div>
);

const OurShip = () => {
  return (
    <div className="min-h-screen py-24">
      <div className="container mx-auto px-4">
        <Title title="COMPANY" des="会社概要" />
        <div className="mt-36 max-w-5xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="p-8 bg-gray-100 border-b border-gray-200">
            <h2 className="text-3xl font-semibold text-gray-800">会社情報</h2>
          </div>
          <div className="p-8">
            <InfoRow label="会社名" value="アワーズシップ株式会社" />
            <InfoRow label="設立" value="2024年6月21日" />
            <InfoRow
              label="所在地"
              value="東京都豊島区東池袋1丁目34番5号 いちご東池袋ビル6階"
            />
            <InfoRow label="資本金" value="300万円" />
            <InfoRow label="代表取締役" value="塩田 悠麻" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurShip;
