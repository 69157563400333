import React from "react";
import { motion } from "framer-motion";
import {
  FaAmazon,
  FaJava,
  FaPython,
  FaSwift,
  FaHtml5,
  FaLinux,
  FaWindows,
  FaDocker,
  FaReact,
  FaJs,
  FaCss3,
  FaNode,
  FaDatabase,
} from "react-icons/fa";
import {
  SiKotlin,
  SiFlutter,
  SiMicrosoftazure,
  SiKubernetes,
  SiAnsible,
  SiTerraform,
} from "react-icons/si";

const FloatingIcons = () => {
  const icons = [
    { Icon: FaAmazon, color: "#FF9900" },
    { Icon: FaJava, color: "#007396" },
    { Icon: FaPython, color: "#3776AB" },
    { Icon: FaSwift, color: "#FA7343" },
    { Icon: FaHtml5, color: "#E34F26" },
    { Icon: FaLinux, color: "#FCC624" },
    { Icon: FaWindows, color: "#0078D6" },
    { Icon: FaDocker, color: "#2496ED" },
    { Icon: FaReact, color: "#61DAFB" },
    { Icon: SiKotlin, color: "#0095D5" },
    { Icon: SiFlutter, color: "#02569B" },
    { Icon: SiMicrosoftazure, color: "#0089D6" },
    { Icon: SiKubernetes, color: "#326CE5" },
    { Icon: SiAnsible, color: "#EE0000" },
    { Icon: SiTerraform, color: "#7B42BC" },
    { Icon: FaJs, color: "#F7DF1E" },
    { Icon: FaCss3, color: "#1572B6" },
    { Icon: FaNode, color: "#339933" },
    { Icon: FaDatabase, color: "#336791" },
  ];

  return (
    <div className="overflow-hidden h-[60px] relative w-full">
      <motion.div
        className="flex items-center h-full absolute left-0"
        initial={{ x: "0%" }}
        animate={{ x: "-50%" }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 40,
            ease: "linear",
          },
        }}
      >
        {[...icons, ...icons].map((icon, index) => (
          <div
            key={index}
            className="text-2xl sm:text-3xl md:text-4xl px-4 sm:px-5 md:px-8"
            style={{ color: icon.color }}
          >
            <icon.Icon />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default FloatingIcons;
