import { useTypewriter, Cursor } from "react-simple-typewriter";
import React, { useRef, useEffect } from "react";
import video from "../../assets/video/video4.mp4";
import "./Banner.css";
import logo from "../../assets/images/1.png";

function BannerIMG() {
  const [text] = useTypewriter({
    words: [" Mission-使命", " Vision-展望", " Value-価値観"],
    loop: true,
    typeSpeed: 80,
    deleteSpeed: 75,
    delaySpeed: 1500,
  });

  const [text1] = useTypewriter({
    words: [
      "すべての人を幸福に",
      "ITを用いて社会にインパクトを与える",
      "人に寄り添い、感謝され信頼を得る",
    ],
    loop: true,
    typeSpeed: 80,
    deleteSpeed: 75,
    delaySpeed: 1500,
  });

  const overlayRef = useRef(null);
  const contentRef = useRef(null);
  const overlayTextRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (overlayRef.current) {
        overlayRef.current.style.display = "none";
      }
      if (contentRef.current) {
        contentRef.current.classList.add("slide-in");
      }
    };

    const overlayElement = overlayRef.current;
    overlayElement.addEventListener("animationend", handleAnimationEnd);

    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.playsInline = true;
      videoRef.current.play().catch((error) => {
        console.log("Auto-play was prevented:", error);
      });
    }

    return () => {
      overlayElement.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  return (
    <div className="w-full h-screen flex items-center relative bg-no-repeat bg-center bg-cover font-bodyFont">
      <video
        ref={videoRef}
        src={video}
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />
      <div ref={overlayRef} className="overlay-slide-in">
        <div ref={overlayTextRef} className="overlay-text">
          <img
            src={logo}
            alt="logo"
            className="w-[500px] h-[120px] object-contain"
          />
        </div>
      </div>
      <div
        ref={contentRef}
        className="relative z-10 flex flex-col justify-center h-full w-full sm:w-1/2 content-slide-in"
      >
        <div className="text-content bg-black bg-opacity-70 h-full p-4 sm:p-8 md:p-16 clip-path-polygon flex flex-col justify-center">
          <div className="text-left">
            <h4 className="text-xl sm:text-3xl md:text-5xl font-bold mb-2 sm:mb-3">
              <span className="text-2xl  sm:text-3xl md:text-7xl font-titleFont text-[#DC143C]">
                Our's Ship
              </span>
            </h4>

            <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-[4rem] font-bold text-[#DC143C] leading-tight">
              日本一
              <span className="text-white capitalize block sm:inline">
                のIT企業をつくろう
              </span>
            </h1>
          </div>
          <div className="py-8 sm:py-12 md:py-16">
            <h2 className="text-xl sm:text-2xl md:text-5xl font-bold text-white h-8 sm:h-10 md:h-12">
              <span className="text-[#DC143C] transition-opacity duration-1000 ease-in-out opacity-100">
                {text}
              </span>
            </h2>
            <h2 className="text-lg sm:text-2xl md:text-4xl font-bold text-white h-10 sm:h-12 md:h-14 mt-4 sm:mt-5 md:mt-6">
              <span className="transition-opacity duration-1000 ease-in-out opacity-100">
                {text1}
              </span>
              <Cursor
                cursorStyle="| "
                cursorColor="red"
                className="absolute left-0 bottom-0"
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerIMG;
