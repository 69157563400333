import React from "react";

const ResumeCard = ({ icon, subtitle, result, des, img }) => {
  return (
    <div className="w-full group flex flex-col sm:flex-row mb-6 sm:mb-0">
      <div className="w-[6px] sm:w-10 h-10 sm:h-[6px] bg-black mr-4 sm:mr-0 sm:mt-16 relative">
        <span className="absolute w-5 h-5 rounded-full -left-2 sm:-top-2 flex justify-center items-center bg-black">
          <span className="w-3 h-3 rounded-full bg-white inline-flex group-hover:bg-orange-500 duration-300"></span>
        </span>
      </div>
      <div className="w-full bg-[#6b7280] bg-opacity-20 hover:bg-opacity-40 duration-300 rounded-lg p-4 sm:px-6 flex flex-col sm:flex-row gap-4 sm:gap-5 shadowOne">
        <div className="flex flex-col gap-2 text-black w-full sm:w-1/2">
          <h3 className="text-3xl sm:text-5xl font-titleFont font-bold text-orange-500">
            {icon}
          </h3>
          <h3 className="text-xl sm:text-2xl font-titleFont font-bold">
            {subtitle}
          </h3>
          <hr className="border-1 border-black" />
          <div className="text-base sm:text-lg font-bodyFont font-semibold">
            {des}
          </div>
        </div>
        <div className="flex justify-center items-center w-full sm:w-1/2 mt-4 sm:mt-0">
          <img src={img} alt="description" className="w-full max-w-[300px]" />
        </div>
      </div>
    </div>
  );
};

export default ResumeCard;
