import React from "react";
import {
  FaCode,
  FaUsers,
  FaCubes,
  FaLaptopCode,
  FaNetworkWired,
} from "react-icons/fa";
import ukeoiImg from "../../assets/images/ukeoi.png";

const UkeoiService = () => {
  return (
    <div
      id="ukeoi-service"
      className="my-8 sm:my-16 md:my-32 font-titleFont flex flex-col items-center"
    >
      <div className="bg-gradient-to-r from-gray-100 to-blue-100 max-w-8xl w-full lg:w-4/5 h-auto p-4 sm:p-8 md:p-12 rounded-xl sm:rounded-2xl shadow-xl sm:shadow-2xl">
        <div className="relative mb-8 sm:mb-12 md:mb-16">
          <div className="absolute inset-0 bg-orange-500 transform -skew-y-3 rounded-2xl sm:rounded-3xl"></div>
          <div className="relative z-10 text-center py-10 sm:py-16 md:py-20">
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-6xl font-bold text-white mb-2 sm:mb-4">
              受託開発事業
            </h2>
            <p className="text-lg sm:text-xl md:text-2xl lg:text-xl text-blue-100">
              高品質なITソリューションを提供
            </p>
            <div className="flex justify-center space-x-4 sm:space-x-6 md:space-x-8 mt-4 sm:mt-6 md:mt-8">
              <FaLaptopCode className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16 lg:h-14 lg:w-14 text-blue-200" />
              <FaNetworkWired className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16 lg:h-14 lg:w-14 text-blue-200" />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between text-black gap-6 sm:gap-8 md:gap-12">
          <div className="lg:w-1/2 mb-6 sm:mb-8 lg:mb-0 bg-white rounded-xl sm:rounded-2xl shadow-xl sm:shadow-2xl hover:shadow-2xl sm:hover:shadow-3xl transition-shadow duration-300 p-4 sm:p-6 md:p-10 lg:p-8">
            <div className="space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-6">
              <p className="flex items-start text-base sm:text-lg md:text-xl lg:text-lg">
                <FaCode className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-7 lg:w-7 mr-2 sm:mr-3 md:mr-4 text-blue-500 flex-shrink-0 mt-1" />
                <span>
                  当社のSES（システムエンジニアリングサービス）事業は経験豊富なITエンジニアを提供し、
                  お客様先に常駐してプロジェクト単位で技術支援、開発支援、インフラ構築支援を行います。
                </span>
              </p>
              <p className="flex items-start text-base sm:text-lg md:text-xl lg:text-lg">
                <FaUsers className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-7 lg:w-7 mr-2 sm:mr-3 md:mr-4 text-blue-500 flex-shrink-0 mt-1" />
                <span>
                  WEBサイト制作、WEBシステム、企業の基幹システム、モバイルアプリなど、さまざまな分野での開発に対応可能です。
                  豊富な経験と最新の技術を持つエンジニアチームが、要件定義から設計、開発、テスト、導入まで一貫してサポートいたします。
                  一部のみのご依頼も対応可能です。
                </span>
              </p>
              <p className="flex items-start text-base sm:text-lg md:text-xl lg:text-lg">
                <FaCubes className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-7 lg:w-7 mr-2 sm:mr-3 md:mr-4 text-blue-500 flex-shrink-0 mt-1" />
                <span>
                  ご要望に応じてシステムを迅速に開発し、運用後のサポートも万全です。
                  お客様が安心してご依頼いただける環境を整え、信頼に応えるサービスを提供します。
                </span>
              </p>
            </div>
          </div>
          <div className="lg:w-1/2">
            <img
              className="rounded-xl sm:rounded-2xl shadow-xl sm:shadow-2xl hover:opacity-90 transition-opacity duration-300 w-full h-auto object-cover"
              src={ukeoiImg}
              alt="受託開発サービスイメージ"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UkeoiService;
