import React from "react";
import companyLogo from "../../assets/images/company1.png";
import recruitLogo from "../../assets/images/recruit.png";
import contactLogo from "../../assets/images/contact.png";
import Card from "./Card";
const BusinessLink = () => {
  return (
    <div className=" grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-10">
      <Card
        cardtitle="COMPANY"
        des="会社概要"
        button={"MORE"}
        backgroundImage={companyLogo}
        linkTo="/Company"
      />
      <Card
        cardtitle="RECRUIT"
        des="採用情報"
        button={"MORE"}
        backgroundImage={recruitLogo}
        linkTo="/Recruit"
      />
      <Card
        cardtitle="CONTACT"
        des="お問い合わせ/パートナー募集"
        button={"MORE"}
        backgroundImage={contactLogo}
        linkTo="/form"
      />
    </div>
  );
};

export default BusinessLink;
