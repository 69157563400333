import React from "react";

const RecruitMSG = () => {
  return (
    <div className="relative bg-gradient-to-r from-blue-500 to-teal-500 py-12 sm:py-16 md:py-24 min-h-screen flex flex-col justify-center">
      <div className="container mx-auto px-4">
        <div className="mb-12 sm:mb-16 md:mb-24 text-center">
          <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white">
            Message
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl mt-2 text-white">
            メッセージ
          </p>
        </div>
        <div className="shadow-2xl rounded-lg p-6 sm:p-8 md:p-10 max-w-4xl mx-auto bg-white bg-opacity-10">
          <div className="text-base sm:text-lg md:text-2xl text-white font-bold leading-relaxed">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">
              日本一のIT企業を作ろう
            </h2>
            <p className="mb-4 sm:mb-6">
              たくさんの人を幸福にしようと思うと、大きなエネルギーが必要になります。
            </p>
            <p className="mb-4 sm:mb-6">
              アワーズシップでは「すべての人を幸福に」を使命として企業活動を行っており、
            </p>
            <p className="mb-4 sm:mb-6">
              そのためには会社規模、技術力、社員の幸福度、お客様への価値提供など、
            </p>
            <p className="mb-4 sm:mb-6">
              あらゆることで日本一を目指し、多くの方へ還元していきたいと考えています。
            </p>
            <p>私たちと共に日本一を目指しませんか。</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitMSG;
