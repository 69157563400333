import React from "react";
import { BsPcDisplayHorizontal } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import sesImg from "../../assets/images/ses.png";
import ukeoiImg from "../../assets/images/ukeoi.png";
import { PiDevicesDuotone } from "react-icons/pi";
import { HashLink } from "react-router-hash-link";
import deveImg from "../../assets/images/develop.png";
import Title from "../Layouts/Title";
import gaming from "../../assets/images/gaming.png";
import { IoGameControllerOutline } from "react-icons/io5";

const SesBusiness = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8">
      <div className="mb-16 sm:mb-20 md:mb-24 font-titleFont">
        <Title title="SERVICE" des="事業内容" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 sm:gap-8">
        <div
          className="relative flex flex-col justify-center items-center w-full h-[300px] sm:h-[320px] md:h-[350px] text-white font-titleFont rounded-lg"
          style={{
            backgroundImage: `url(${sesImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
          <div className="relative z-10 flex flex-col justify-center items-center gap-4 sm:gap-5 md:gap-6">
            <BsPcDisplayHorizontal className="text-5xl sm:text-6xl md:text-7xl font-bold" />
            <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold">
              SES事業
            </h2>
            <div className="flex gap-6">
              <HashLink to={"/#ses-service"}>
                <button className="bg-white text-black py-2 px-6 sm:py-2 sm:px-7 md:py-3 md:px-8 rounded-lg text-base sm:text-lg font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                  詳細を見る
                </button>
              </HashLink>
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-col justify-center items-center w-full h-[300px] sm:h-[320px] md:h-[350px] text-white rounded-lg"
          style={{
            backgroundImage: `url(${ukeoiImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
          <div className="relative z-10 flex flex-col justify-center items-center gap-4 sm:gap-5 md:gap-6">
            <IoIosPeople className="text-5xl sm:text-6xl md:text-7xl font-bold" />
            <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold">
              受託開発事業
            </h2>
            <div className="flex gap-6">
              <HashLink to={"/#ukeoi-service"}>
                <button className="bg-bodyColor text-black py-2 px-6 sm:py-2 sm:px-7 md:py-3 md:px-8 rounded-lg text-base sm:text-lg font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                  詳細を見る
                </button>
              </HashLink>
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-col justify-center items-center w-full h-[300px] sm:h-[320px] md:h-[350px] text-white rounded-lg"
          style={{
            backgroundImage: `url(${deveImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
          <div className="relative z-10 flex flex-col justify-center items-center gap-4 sm:gap-5 md:gap-6">
            <PiDevicesDuotone className="text-5xl sm:text-6xl md:text-7xl font-bold" />
            <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold">
              ラボ型開発サービス
            </h2>
            <div className="flex gap-6">
              <HashLink to={"/#labo-service"}>
                <button className="bg-white text-black py-2 px-6 sm:py-2 sm:px-7 md:py-3 md:px-8 rounded-lg text-base sm:text-lg font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                  詳細を見る
                </button>
              </HashLink>
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-col justify-center items-center w-full h-[300px] sm:h-[320px] md:h-[350px] text-white rounded-lg"
          style={{
            backgroundImage: `url(${gaming})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
          <div className="relative z-10 flex flex-col justify-center items-center gap-4 sm:gap-5 md:gap-6">
            <IoGameControllerOutline className="text-5xl sm:text-6xl md:text-7xl font-bold" />
            <h2 className="text-3xl sm:text-3xl md:text-4xl font-bold">
              esports事業
            </h2>
            <div className="flex gap-6">
              <a
                href="https://gaming.oursship.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-white text-black py-2 px-6 sm:py-2 sm:px-7 md:py-3 md:px-8 rounded-lg text-base sm:text-lg font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                  詳細を見る
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SesBusiness;
