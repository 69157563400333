import React, { useState } from "react";
import Title from "../Layouts/Title";
import { FaPlusCircle } from "react-icons/fa";
import { SlMinus } from "react-icons/sl";

const ItRecruit = () => {
  const [isOpenIT, setIsOpenIT] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [isOpenHR, setIsOpenHR] = useState(false);
  const [isOpenPR, setIsOpenPR] = useState(false);
  const [isOpenSales, setIsOpenSales] = useState(false);
  const [isOpenFinance, setIsOpenFinance] = useState(false);

  return (
    <div className="px-4 md:px-24 font-titleFont">
      <div className="py-12 md:py-24">
        <Title title="RECRUIT" des="採用情報" />
      </div>
      <div className="my-12 md:my-24">
        <button
          className="flex justify-between w-full bg-orange-400 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
          onClick={() => setIsOpenIT(!isOpenIT)}
        >
          <span className="text-2xl md:text-4xl font-titleFont font-bold">
            ITエンジニア
          </span>
          <span className="text-2xl md:text-4xl">
            {isOpenIT ? <SlMinus /> : <FaPlusCircle />}
          </span>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
          ${isOpenIT ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
            <p className="text-xl font-bold">募集要項</p>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">雇用形態</p>
              <p>・正社員</p>
              <p>・フリーランスの方の案件探しもお気軽にご相談下さい </p>
              <p>◯還元率80％、複数社登録OK</p>
            </div>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">業務内容</p>
              <p>システムエンジニアリングサービス（SES）</p>
              <p>採用後、お客様先プロジェクトへの配属となります。</p>
              <p>
                お客様のソフトウェア開発、基盤構築、運用・保守、テストなどの
                サポートを行って頂きます。
              </p>
            </div>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">【エンジニア】</p>
              <p>1. PM / PMO </p>
              <p>2. ソフトウェア開発エンジニア</p>
              <p>3. WEB制作エンジニア</p>
              <p>4. サーバー / ネットワーク構築エンジニア</p>
              <p>※その他の技術をお持ちの方もお気軽にご相談下さい。</p>
            </div>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">応募資格</p>
              <p>・IT業界での何かしらの実務経験1年以上</p>
              <p>・理念に共感できる方</p>
              <p>・日本国籍の方</p>
              <p>※年齢学歴は問いません</p>
            </div>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">勤務地</p>
              <p>プロジェクト先(首都圏 or リモートワーク)</p>
              <p>※首都圏以外の方もご相談ください。</p>
              <p>お客様先に常駐してプロジェクトのお手伝いをして頂きます。</p>
            </div>
            <hr />
            <div className="text-left">
              <p className="text-xl font-bold">勤務時間</p>
              <p>完全週休2日制(土日)、祝日</p>
              <p>
                ※PJ先によって多少異なる場合がありますが、本人の希望を考慮します。
              </p>
              <p>◯年間休日１２５日以上</p>
            </div>
            <div className="text-left">
              <p className="text-xl font-bold">給与</p>
              <p>【月給】¥210,000〜¥700,000（賞与有り）</p>
              <p>※固定残業代10,000円5h分を含む（超過分別途全額支給）</p>
              <p>入社1年後に固定残業をなくし、基本給へ算入します。</p>
              <p>◯昇給7月(年1回)　賞与6月、12月</p>
              <p>現状のスキルによって判断します。</p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <button
            className="flex justify-between w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
            onClick={() => setIsOpenAdmin(!isOpenAdmin)}
          >
            <span className="text-2xl md:text-4xl font-titleFont font-bold">
              総務
            </span>
            <span className="text-2xl md:text-4xl">
              {isOpenAdmin ? <SlMinus /> : <FaPlusCircle />}
            </span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
            ${isOpenAdmin ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
          >
            <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
              <p>現在は募集はございません</p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <button
            className="flex justify-between w-full bg-orange-600 text-white p-3 rounded-lg hover:bg-red-600  transition-all duration-300 ease-in-out"
            onClick={() => setIsOpenHR(!isOpenHR)}
          >
            <span className="text-2xl md:text-4xl font-titleFont font-bold">
              人事
            </span>
            <span className="text-2xl md:text-4xl">
              {isOpenHR ? <SlMinus /> : <FaPlusCircle />}
            </span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
            ${isOpenHR ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
          >
            <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
              <p>現在は募集はございません</p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <button
            className="flex justify-between w-full bg-orange-700 text-white p-3 rounded-lg hover:bg-red-600  transition-all duration-300 ease-in-out"
            onClick={() => setIsOpenPR(!isOpenPR)}
          >
            <span className="text-2xl md:text-4xl font-titleFont font-bold">
              広報
            </span>
            <span className="text-2xl md:text-4xl">
              {isOpenPR ? <SlMinus /> : <FaPlusCircle />}
            </span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
            ${isOpenPR ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
          >
            <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
              <p>現在は募集はございません</p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <button
            className="flex justify-between w-full bg-orange-800 text-white p-3 rounded-lg hover:bg-yellow-600 transition-all duration-300 ease-in-out"
            onClick={() => setIsOpenSales(!isOpenSales)}
          >
            <span className="text-2xl md:text-4xl font-titleFont font-bold">
              営業/営業事務
            </span>
            <span className="text-2xl md:text-4xl">
              {isOpenSales ? <SlMinus /> : <FaPlusCircle />}
            </span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
            ${isOpenSales ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
          >
            <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
              <p>現在は募集はございません</p>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <button
            className="flex justify-between w-full bg-orange-900 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
            onClick={() => setIsOpenFinance(!isOpenFinance)}
          >
            <span className="text-2xl md:text-4xl font-titleFont font-bold">
              経理/財務
            </span>
            <span className="text-2xl md:text-4xl">
              {isOpenFinance ? <SlMinus /> : <FaPlusCircle />}
            </span>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-base 
            ${isOpenFinance ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
          >
            <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
              <p>現在は募集はございません</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-6 py-8 rounded-lg text-black flex items-center justify-center font-titleFont my-6 md:my-10">
        <div className="flex flex-col gap-4 justify-center items-center p-5 mt-8">
          <div className="text-left">
            <p className="text-xl md:text-2xl text-black font-bold ">
              アワーズシップでは面接といっても気楽に話せるカジュアルな場を用意しています！
            </p>
            <div className="text-xl md:text-2xl text-black py-6 flex flex-col gap-2">
              <p>まずはどんな会社なのか知りたいという方は、</p>
              <p>お問い合わせよりカジュアル面談希望とご連絡ください！</p>
              <p>履歴書等は不要でご準備も必要ありません。</p>
              <p>まずはWeb会議にて気軽にお話しましょう！</p>
            </div>

            <div className="text-xl md:text-2xl text-black py-6 flex flex-col gap-2">
              <p className="font-semibold">・現在の業務について</p>
              <p className="font-semibold">・今後のキャリアプラン、人生設計</p>
              <p className="font-semibold">・給与について</p>
              <p className="font-semibold">・働き方について</p>
              <p className="font-semibold">・チャレンジしたいこと</p>
            </div>
            <div>
              <p className="text-xl md:text-2xl text-black">
                など悩んでることがあればなんでも気軽にご相談ください！
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItRecruit;
