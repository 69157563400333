import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { logo } from "../assets/index";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = (elem) => {
    const yCoordinate = 0;
    const duration = 1000;
    window.scrollTo({
      top: yCoordinate,
      behavior: "smooth",
    });
  };

  const HamburgerIcon = ({ isOpen, setIsOpen }) => (
    <button
      className="flex flex-col justify-around w-8 h-8 bg-transparent border-none cursor-pointer p-0 z-50 focus:outline-none"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span
        className={`block h-0.5 w-8 bg-white rounded-sm transition-all duration-300 ease-out ${
          isOpen ? "rotate-45 translate-y-2.5" : ""
        }`}
      />
      <span
        className={`block h-0.5 w-8 bg-white rounded-sm transition-all duration-300 ease-out ${
          isOpen ? "opacity-0" : "opacity-100"
        }`}
      />
      <span
        className={`block h-0.5 w-8 bg-white rounded-sm transition-all duration-300 ease-out ${
          isOpen ? "-rotate-45 -translate-y-2.5" : ""
        }`}
      />
    </button>
  );

  return (
    <div
      className={`w-full h-16 md:h-24 sticky top-0 z-40 mx-auto flex justify-between items-center font-titleFont border-b-[1px]  ${
        showNavbar ? "opacity-100" : "opacity-0 pointer-events-none"
      } ${isTop ? "bg-[#141414]" : "bg-[#141414] bg-opacity-45"} text-white`}
    >
      <div className="flex items-center justify-between w-full px-4 md:px-8">
        <HashLink
          smooth
          to="/"
          scroll={scrollToTop}
          onClick={() => scrollToTop()}
        >
          <img
            src={logo}
            alt="logo"
            className="w-[150px] md:w-[200px] h-[40px] md:h-[50px] cursor-pointer"
          ></img>
        </HashLink>

        <div className="md:hidden relative z-50">
          <HamburgerIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>

        <ul
          className={`flex-col md:flex-row md:flex fixed md:static inset-0 md:inset-auto w-full h-full md:w-auto md:h-auto bg-[#141414] md:bg-transparent ${
            isOpen ? "flex justify-center items-center" : "hidden"
          } md:items-center gap-8 md:gap-10 transition-all duration-300 ease-in-out
          ${isOpen ? "opacity-95" : "opacity-0"} md:opacity-100 z-40`}
        >
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
              scrollToTop();
            }}
          >
            <HashLink
              smooth
              to="/"
              scroll={scrollToTop}
              className="block text-center"
            >
              TOP
            </HashLink>
          </li>
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <HashLink smooth to="/#features" className="block text-center">
              事業内容
            </HashLink>
          </li>
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <RouterLink
              to="/Company"
              className="block text-center"
              style={{ textDecoration: "none" }}
            >
              会社概要
            </RouterLink>
          </li>
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <RouterLink
              to="Recruit"
              className="block text-center"
              style={{ textDecoration: "none" }}
            >
              採用情報
            </RouterLink>
          </li>
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <a
              href="https://gaming.oursship.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our’ｓShipGaming
            </a>
          </li>
          <li
            className="text-2xl md:text-lg font-bold text-gray-100 hover:text-designColor cursor-pointer duration-300 p-4 md:p-0"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <RouterLink
              to="/form"
              className="block text-center"
              style={{ textDecoration: "none" }}
            >
              お問い合わせ/パートナー募集
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
