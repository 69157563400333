import React from "react";
import BusinessLink from "./BusinessLink";
import SesBusiness from "./SesBusiness";
import SesService from "./SesService";
import Ukeoi from "./Ukeoi";
import Skill from "./Skill";
import Labo from "./Labo";
import LeftBanner from "../Banner/LeftBanner";

const Features = () => {
  return (
    <>
      <section id="features" className="w-full h-auto ">
        <SesBusiness />
        <hr className="my-24 border-t-1 border-gray-300" />
      </section>
      <div className="w-full h-auto">
        <Skill />
      </div>
      <hr className="my-24 border-none border-gray-300" />
      <div className="w-full h-auto">
        <SesService />
        <hr className="my-24 border-t-1 border-gray-300" />
      </div>
      <div className="w-full h-auto">
        <Ukeoi />
        <hr className="my-24 border-t-1 border-gray-300" />
      </div>
      <div className="w-full h-auto ">
        <Labo />
      </div>
      <hr className="my-24 border-t-1 border-gray-300" />
      <>
        <BusinessLink />
      </>
      <hr className="my-24 " />
    </>
  );
};

export default Features;
