import React, { useEffect, useState, useRef } from "react";
import Features from "../Features/Features";
import Map from "./Map";
import CompanyName from "../CompanyName/CompanyName";
import BannerIMG from "./BannerIMG";
import CenterBannerTest from "./CenterBannerTest";

const Banner = () => {
  const [bgColor, setBgColor] = useState("bg-[#333333]");
  const bannerRef = useRef(null);
  const companyNameRef = useRef(null);
  const centerBannerRef = useRef(null);
  const featuresRef = useRef(null);
  const mapRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const bannerHeight = bannerRef.current.offsetHeight;

    const companyNameHeight = companyNameRef.current.offsetHeight;
    const centerBannerHeight = centerBannerRef.current.offsetHeight;

    if (
      scrollPosition <
      bannerHeight + companyNameHeight + centerBannerHeight
    ) {
      setBgColor("bg-[#141414]");
    } else {
      setBgColor("bg-[#FDFDFD]");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`w-full ${bgColor} transition-colors duration-1000`}>
      <section
        ref={bannerRef}
        className="w-full flex flex-col md:flex-row items-center "
      >
        <BannerIMG />
      </section>
      <hr className="my-24 border-none" />
      <div
        ref={companyNameRef}
        className="max-w-screen-2xl mx-auto bg-[#141414]"
      >
        <CompanyName />
      </div>
      <hr className="my-24 border-none" />
      <div ref={centerBannerRef}>
        <CenterBannerTest />
      </div>
      <hr className="my-24 border-t-1 border-gray-300" />
      <div ref={featuresRef} className="max-w-screen-2xl mx-auto">
        <Features />
      </div>
      <hr className="my-24 border-t-1 border-gray-300" />
      <div ref={mapRef} className="bg-[#141414]">
        <Map />
      </div>
    </div>
  );
};

export default Banner;
