import React from "react";

const Title = ({ title, des }) => {
  return (
    <div className="font-titleFont mb-6 xs:mb-8 sm:mb-10 relative">
      <h3 className="text-5xl xs:text-6xl sm:text-7xl md:text-8xl lg:text-9xl xl:text-10xl font-extrabold text-blue-200 relative z-10">
        {title}
      </h3>
      <h1 className="text-2xl xs:text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-black absolute top-12 xs:top-10 sm:top-10 md:top-16 lg:top-22 xl:top-20 left-0 z-20">
        {des}
      </h1>
    </div>
  );
};

export default Title;
