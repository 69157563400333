import React, { useState, useEffect } from "react";
import companyImage from "../../assets/images/companyname2.png";

const CompanyName = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full flex justify-center items-center shadow-shadowOne">
      <img
        src={companyImage}
        alt="個人主義が強くなっていく世の中で、
社員皆が会社という船の乗組員になり、同じ方向に向かって
一致団結していけるよう願った社名となります。

最初は小舟でも皆で協力し巨大な船にしていき
新たな船(新規事業)を生み出し船団となっていくことを目指しています。
巨大な船にしていくため、一緒に成長し
アワーズシップが目指す未来に向けて協力してくれる仲間を求めています。"
        className="w-full max-w-full h-auto sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl object-contain cursor-pointer"
        onClick={openModal}
      />

      {modalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50"
          onClick={closeModal}
        >
          <div className="w-full h-full max-w-full max-h-full p-4 flex flex-col justify-center items-center">
            <img
              src={companyImage}
              alt="Company Logo"
              className="w-full h-auto max-h-[80vh] object-contain cursor-pointer sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl"
              onClick={(e) => e.stopPropagation()}
            />
            <button
              className={`mt-4 text-designColor text-xl font-bold border-2 border-designColor px-4 py-2 ${
                isMobile ? "self-center" : "absolute top-20 right-20"
              }`}
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyName;
