import React from "react";

const Map = () => {
  return (
    <div className="w-full pb-5 sm:pb-10">
      <div className="bg-[#141414] p-3 sm:p-5 md:p-15 flex flex-col md:flex-row justify-center items-center gap-5 md:gap-10 transition-colors duration-100">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6477.435668739433!2d139.7157829!3d35.733157399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d669148632b%3A0x358dc22b0c248dc7!2z44CSMTcwLTAwMTMg5p2x5Lqs6YO96LGK5bO25Yy65p2x5rGg6KKL77yR5LiB55uu77yT77yU4oiS77yVIOOBhOOBoeOBlOadseaxoOiii-ODk-ODqyA26ZqO!5e0!3m2!1sja!2sjp!4v1717575089612!5m2!1sja!2sjp"
          className="w-full md:w-3/5 h-64 sm:h-96 md:h-[450px] border-0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        ></iframe>
        <div className="text-sm sm:text-base font-bodyFont leading-6 tracking-wide pt-3 sm:pt-5 text-white">
          <address className="not-italic">
            東京都豊島区東池袋1丁目34番5号 いちご東池袋ビル 6階
          </address>

          <p>〒170-0013</p>
          <a
            href="https://maps.app.goo.gl/Rc4A2VAkCEDo7FMH6"
            target="_blank"
            rel="noreferrer"
            className="text-designColor"
          >
            Google Map
          </a>
        </div>
      </div>
    </div>
  );
};

export default Map;
