import React from "react";
import { Link } from "react-router-dom";

const LinkCard = ({ cardtitle, des, button, backgroundImage, linkTo }) => {
  return (
    <div className="relative w-full h-80 rounded-lg  overflow-hidden">
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="absolute inset-0 bg-black opacity-50" />
      <div className="relative z-10 w-full h-full px-4 xs:px-6 sm:px-8 md:px-12 py-6 xs:py-8 sm:py-10 flex items-center transition-colors duration-100">
        <div className="flex flex-col gap-2 xs:gap-3 sm:gap-4">
          <h2 className="text-3xl xs:text-4xl sm:text-5xl md:text-6xl lg:text-7xl pb-2 xs:pb-4 sm:pb-6 md:pb-10 font-titleFont text-white font-bold justify-center items-center">
            {cardtitle}
          </h2>
          <div className="text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl font-bold pb-2 xs:pb-3 sm:pb-4 md:pb-5 font-bodyFont text-white">
            <p>{des}</p>
          </div>
          <div>
            <Link to={linkTo}>
              <button className="bg-white text-black py-1 xs:py-1.5 sm:py-2 px-2 xs:px-3 sm:px-4 rounded-lg text-sm xs:text-base sm:text-lg md:text-xl font-bold hover:bg-orange-500 hover:text-white transition-colors duration-300">
                {button}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkCard;
